import { MULTICHAIN_SUPPORT_ID } from './multichain';
import { isConnected, getPublicKey, getNetwork } from '@stellar/freighter-api';
import WalletConnectProvider from "@walletconnect/web3-provider";
window.WalletConnectProvider = WalletConnectProvider


const rpc = Object.fromEntries(
  Object.entries(MULTICHAIN_SUPPORT_ID).map(([_, settings]) => [
    Number(settings.decimal),
    (settings.rpcUrls && settings.rpcUrls[0]) || '',
  ])
)
const walletConnectProvider = new WalletConnectProvider({ rpc })
window.walletConnectProvider = walletConnectProvider

export const WALLET = {
  binanceChain: {
    isActive: true,
    key: 'binanceChain',
    chain: 'ethereum',
    name: 'Binance Chain Wallet',
    logo: '/images/icon/icon-binance-currency.svg',
    icon: '/images/logo/logo-bsc.svg',
    injectedObject: 'BinanceChain',
    request: {
      connect: () =>
        window.BinanceChain.request({
          method: 'eth_accounts',
        }),
      network: () =>
        window.BinanceChain.request({
          method: 'net_version',
        }),
      sendTransaction: (params) =>
        window.BinanceChain.request({
          method: 'eth_sendTransaction',
          params,
        }),
    },
    methods: {
      switchNetwork: (chainId) => {
        const chain = Object.keys(MULTICHAIN_SUPPORT_ID).find(
          (item) => MULTICHAIN_SUPPORT_ID[item].hex == chainId
        );
        return window.BinanceChain.switchNetwork(
          MULTICHAIN_SUPPORT_ID[chain].bscChainSwitch
        );
      },
    },
    installation_link:
      'https://chrome.google.com/webstore/detail/binance-chain-wallet/fhbohimaelbohpjbbldcngcnapndodjp?utm_source=chrome-ntp-icon',
  },
  metamask: {
    isActive: true,
    key: 'metamask',
    chain: 'ethereum',
    name: 'Metamask',
    logo: '/images/logo/logo-metamask-white.svg',
    icon: '/images/logo/logo-metamask.svg',
    injectedObject: 'ethereum',
    deeplink: 'https://metamask.app.link/dapp/',
    request: {
      connect: () =>
        window.ethereum.request({
          method: 'eth_requestAccounts',
        }),
      network: () =>
        window.ethereum.request({
          method: 'net_version',
        }),
      sendTransaction: (params) =>
        window.ethereum.request({
          method: 'eth_sendTransaction',
          params,
        }),
    },
    methods: {
      switchNetwork: (chainId) =>
        window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chainId }],
        }),
    },
    installation_link:
      'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=chrome-ntp-icon',
  },
  walletConnect: {
    isActive: true,
    key: 'walletConnect',
    chain: 'ethereum',
    name: 'Wallet Connect',
    logo: '/images/logo/logo-walletconect.svg',
    icon: '/images/logo/logo-walletconect.svg',
    injectedObject: 'walletConnectProvider',
    deeplink: 'https://metamask.app.link/dapp/',
    request: {
      connect: async () => {
        window.walletConnectProvider = new WalletConnectProvider({ rpc })
        await window.walletConnectProvider.enable()
        return window.walletConnectProvider.accounts
      },
      network: () =>
        window.walletConnectProvider.request({
          method: 'net_version',
        }),
      sendTransaction: (params) =>
        window.walletConnectProvider.request({
          method: 'eth_sendTransaction',
          params,
        }),
    },
    methods: {
      switchNetwork: (chainId) =>
        window.walletConnectProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chainId }],
        }),
      disconnect: (chainId) =>
        window.walletConnectProvider.disconnect()
    },
    installation_link:
      'https://metamask.io/download/',
  },
  freighter: {
    isActive: true,
    key: 'freighter',
    chain: 'stellar',
    isInstalled: isConnected,
    name: 'Freighter',
    logo: '/images/logo/logo-freighter.svg',
    icon: '/images/logo/logo-freighter.svg',
    injectedObject: null, //unknown
    request: {
      connect: () => getPublicKey(),
      network: () => getNetwork(),
    },
    installation_link:
      'https://chrome.google.com/webstore/detail/freighter/bcacfldlkkdogcmkkibnjlakofdplcbk',
  },
};
