import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/lib/modal/Modal';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Image } from 'components';
import { MULTICHAIN_SUPPORT_ID, NETWORK_ORDER } from 'constants/multichain';
import { WALLET } from 'constants/walletExtension';
import { useUIContext } from 'pages/ui-context';
import { ToAbsoluteUrl } from 'ultis';
import './style.scss';
import { Link } from 'react-router-dom';

ModalConnectNetwork.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  onCancel: PropTypes.func,
};

ModalConnectNetwork.defaultProps = {
  visible: false,
  title: 'Connect wallet',
};
const getSupportNetwork = (walletName, network) => {
  let res = false;
  switch (walletName) {
    case 'walletConnect': {
      if (network.hex) res = true;
      else res = false;
      break;
    }
    case 'metamask': {
      if (network.hex) res = true;
      else res = false;
      break;
    }
    case 'binanceChain': {
      if (network.bscChainSwitch) res = true;
      else res = false;
      break;
    }
    case 'freighter': {
      if (network.key) res = true;
      else res = false;
      break;
    }
  }
  return res;
};
const walletOrder = ['metamask', 'binanceChain', 'freighter', 'walletConnect'];

const WalletItem = ({
  index,
  data,
  onSelect,
  isAgreeTerm,
  selectedNetwork,
  selectedWallet,
}) => {
  const isSupport = useMemo(
    () =>
      WALLET[data].isActive &&
      isAgreeTerm &&
      getSupportNetwork(data, selectedNetwork) &&
      WALLET[data].chain === selectedNetwork.chain,
    [data, isAgreeTerm, selectedNetwork]
  );
  return (
    <div
      key={`${index}_${WALLET[data].name}`}
      className={`list-item wallet mt-lg mb-xl ${WALLET[data].name === selectedWallet?.name ? 'active' : ''
        } ${isSupport ? '' : 'disable'}`}
      onClick={isSupport ? () => onSelect(WALLET[data]) : () => { }}
    >
      <div className='list-item__icon'>
        <Image
          className='icon__image'
          errorClass='icon__image--error'
          src={ToAbsoluteUrl(WALLET[data].logo)}
          alt={WALLET[data].name}
        />
      </div>
      <span className='list-item__text'>{WALLET[data].name}</span>
    </div>
  );
};

function ModalConnectNetwork({ visible, title, onCancel, ...props }) {
  const { connectWallet, setWallet, setWalletWarning, network, isMobile } =
    useUIContext();
  const [isAgreeTerm, setIsAgreeTerm] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState(null);

  useEffect(() => {
    if (visible) setSelectedNetwork(network);
  }, [visible, network]);

  const handleCancel = () => {
    setIsAgreeTerm(false);
    setSelectedWallet(null);
    // setSelectedNetwork(network);
    onCancel();
  };
  const handleCheckTerm = (e) => setIsAgreeTerm(e.target.checked);

  const handleSelectNetwork = (network) => {
    setSelectedNetwork(network);
  };
  const handleSelectWallet = (wallet) => {
    const isWalletAvaiable = wallet?.isInstalled
      ? wallet?.isInstalled()
      : window[wallet?.injectedObject] !== undefined;
    if (!isWalletAvaiable) {
      if (isMobile && wallet.deeplink) {
        let page = '';
        if (window.origin.includes('https://'))
          page = origin.replace('https://', '');
        if (window.origin.includes('http://'))
          page = origin.replace('http://', '');
        window.open(wallet.deeplink + page);
        handleCancel();
        return;
      }
      setWalletWarning(true);
      setWallet(wallet);
      return;
    }
    setSelectedWallet(wallet);
    handleConnectWallet(wallet);
  };
  const handleConnectWallet = async (wallet = selectedWallet) => {
    if (!selectedNetwork) return;
    const [_, error] = await connectWallet(wallet, selectedNetwork);
    // if (error) {
    //   message.warning(`${error.code} ${error.message}`, 4);
    // }
    handleCancel();
  };
  // render
  const renderNetwork = useMemo(
    () =>
      NETWORK_ORDER.filter(networkName => MULTICHAIN_SUPPORT_ID[networkName].isSupport).map((networkName, index) => (
        <div
          key={`${index}_${MULTICHAIN_SUPPORT_ID[networkName].hex}`}
          className={`list-item mt-lg mb-xl ${MULTICHAIN_SUPPORT_ID[networkName].isSupport && isAgreeTerm
            ? MULTICHAIN_SUPPORT_ID[networkName].hex ==
            selectedNetwork?.hex && 'active'
            : 'disable'
            }`}
          onClick={
            MULTICHAIN_SUPPORT_ID[networkName].isSupport && isAgreeTerm
              ? () => handleSelectNetwork(MULTICHAIN_SUPPORT_ID[networkName])
              : () => { }
          }
        >
          <div className='list-item__icon'>
            <Image
              className='icon__image'
              errorClass='icon__image--error'
              src={ToAbsoluteUrl(MULTICHAIN_SUPPORT_ID[networkName].icon)}
              alt={MULTICHAIN_SUPPORT_ID[networkName].altName}
            />
            <div className='icon__checked'>
              <Image
                className='checked__image'
                errorClass='checked__image--error'
                src={ToAbsoluteUrl('/images/icon/icon-checked.svg')}
              />
            </div>
          </div>
          <span className='list-item__text'>
            {MULTICHAIN_SUPPORT_ID[networkName].altName}
          </span>
        </div>
      )),

    [selectedNetwork, handleSelectNetwork, isAgreeTerm]
  );

  const renderWallet = useMemo(
    () =>
      walletOrder.map((walletName, index) => (
        <WalletItem
          key={`${index}_${walletName}`}
          index={index}
          data={walletName}
          isAgreeTerm={isAgreeTerm}
          onSelect={handleSelectWallet}
          selectedNetwork={selectedNetwork}
          selectedWallet={selectedWallet}
        />
      )),
    [selectedWallet, handleSelectWallet, isAgreeTerm, selectedNetwork]
  );
  return (
    <Modal
      {...props}
      visible={visible}
      title={title}
      centered
      closeIcon={
        <Image
          className='btn-close'
          errorClass='btn-close--error'
          src={ToAbsoluteUrl('/images/icon/icon-close-btn.svg')}
        />
      }
      onCancel={handleCancel}
      className='modal modal-connect-network'
      footer={null}
    >
      <ol>
        <li>
          <span>
            {'Accept '}
            <Link to='/documents/term_of_service' target='_blank'>
              Terms of Service
            </Link>
            {' and '}
            <Link to='/documents/term_of_service' target='_blank'>
              Privacy Policy
            </Link>
          </span>
          <div>
            <Checkbox onChange={handleCheckTerm} checked={isAgreeTerm}>
              <span>I read and accept</span>
            </Checkbox>
          </div>
        </li>
        <li>
          <span>Choose Network</span>
          <div className='list'>{renderNetwork}</div>
        </li>
        <li>
          <span>Choose Wallet</span>
          <div className='list'>{renderWallet}</div>
        </li>
      </ol>
    </Modal>
  );
}

export default ModalConnectNetwork;
