import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { useLocation } from 'react-router-dom';
import { ModalError } from 'components';
import { useUIContext } from 'pages/ui-context';
import { WALLET } from 'constants/walletExtension';
import FormFaucet from './components/form-faucet';
import './styles.scss';
import withHeader from 'hocs/withHeader';
import { ToAbsoluteUrl, openMetaMaskUrl } from 'ultis';

const EvryHub = (props) => {
  const { wallet, walletWarning, setWalletWarning, setWallet, connectWallet, network, isMobile } = useUIContext();
  const { search } = useLocation()
  const [walletModalVisible, setWalletModalVisible] = useState(false)
  const pageQuery = useMemo(() => {
    const query = new URLSearchParams(search);
    return {
      autoChain: query.get('auto-chain') !== 'false',
    };
  }, [search]);

  const handleCloseWarningModal = useCallback(() => {
    setWallet(null);
    setWalletWarning(false);
  }, []);

  const connectMetamask = useCallback(async () => {
    try {
      if (window.ethereum) {
        const [_, error] = await connectWallet(WALLET.metamask, network);
        if (error) {
          throw error
        }
        setWalletModalVisible(false);
      } else {
        openMetaMaskUrl(window.location.href)
      }
    } catch (error) {
      console.log(error);
    }
  }, [connectWallet, network]);

  const connectWalletConnect = useCallback(async () => {
    try {
      const [_, error] = await connectWallet(WALLET.walletConnect, network);
      if (error) {
        throw error
      }
      setWalletModalVisible(false);
    } catch (error) {
      console.log(error);
    }
  }, [connectWallet, network]);

  useEffect(async () => {
    if (pageQuery.autoChain) {
      try {
        if (window.ethereum) {
          const [_, error] = await connectWallet(WALLET.metamask, network);
        } else {
          setWalletModalVisible(true);
          // const [_, error] = await connectWallet(WALLET.walletConnect, network);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [pageQuery.autoChain])

  const renderFormFaucet = useMemo(() => <FormFaucet />, []);

  return (
    <div className='new-home'>
      <div className='layout'>
        <div className='main-section'>
          <div className='home-descrip'>
            Faucet by Nova
          </div>
          <div>
            <div>
              <div className={'_mgbt-6 _pdh-6 _pdh-0:sm'}>
                {/* <div className={'_fs-10 _fw-600'}>Nova Faucet</div> */}
                {/* <p className={'_fs-4 _mgt-3'}>The safe, fast and most secure way  to bring cross-chain asset</p> */}
              </div>
              <div className={'swap-content'}>
                <div className='swap-content__layout nm-card'>{renderFormFaucet}</div>
              </div>
            </div>
          </div>
        </div>
        <div className='_dp-f _fdrt-r _alit-ct _jtfct-ct'>
          <div className='_mgr-5' style={{ color: 'hsl(var(--hsl-content)/0.65)' }}>
            Next to
          </div>
          <a href="https://universe.velofinance.io/" target="_blank" rel="noreferrer" className='nm-button is-variant-tertiary is-size-large is-pill'>
            <img src="/images/logo/logo-velo-universe.svg" alt="velo-universe" className='_w-13' />
          </a>
        </div>
      </div>
      <ModalError
        wallet={wallet}
        visible={walletWarning}
        onCancel={handleCloseWarningModal}
        destroyOnClose
      />
      <Modal
        visible={walletModalVisible}
        title="Connect Wallet"
        centered
        onCancel={() => setWalletModalVisible(false)}
        className='modal modal-normal'
        closeIcon={<img src={ToAbsoluteUrl('/images/icon/icon-close-btn.svg')} alt="close-button" />}
        footer={
          <Button
            onClick={() => setWalletModalVisible(false)}
            block
            className='modal-normal-dismiss__close-btn'
          >
            Close
          </Button>}
      >

        {/* <img
          src={ToAbsoluteUrl(
            '/images/icon/icon-checked.svg'
          )}
          alt=''
        /> */}
        <h6 style={{ width: '100%', textAlign: 'center' }} className="_mgbt-5">
          Choose Wallet to Continue
        </h6>
        <div className='lo-6 g-7'>
          <div className="_cs-pt _dp-f _fdrt-cl _jtfct-ct _alit-ct _g-4 _bgcl-base-100:hover _bdrd-4 _pd-4" onClick={connectMetamask}>
            <img className="_w-11 _h-11 _ojf-ct" src="/images/logo/logo-metamask.svg" alt="Metamask" />
            <div>Metamask</div>
          </div>
          <div className="_cs-pt _dp-f _fdrt-cl _jtfct-ct _alit-ct _g-4 _bgcl-base-100:hover _bdrd-4 _pd-4" onClick={connectWalletConnect}>
            <img className="_w-11 _h-11 _ojf-ct" src="/images/logo/logo-walletconect.svg" alt="Wallet Connect" />
            <div>Wallet Connect</div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default withHeader(EvryHub);
